import { objectToQueryString, queryStringToObject } from 'modules/query-string'
import { formatFilterParams } from 'modules/request/format-filter-params'
import * as _ from 'modules/util'
import * as L from 'partial.lenses'
import * as R from 'ramda'

export const asAbsoluteUrl = url => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`
  return _.thru(url, R.unless(R.startsWith('http'), R.concat(baseUrl)))
}

export const findCollectionKey = data => {
  if (!_.isObject(data)) return

  const keys = Object.keys(data)
  const possibleCollectionKeys = R.difference(keys, ['links', 'totalEntries'])
  const possibleCollection = data[possibleCollectionKeys[0]]
  if (
    keys.length <= 3 &&
    keys.includes('links') &&
    possibleCollectionKeys.length === 1 &&
    _.isArray(possibleCollection)
  ) {
    return possibleCollectionKeys[0]
  }
}

export const joinUrlWithParams = (urlIn, params = {}) => {
  const splitUrlAndQueryString = R.split('?', urlIn)
  const url = R.head(splitUrlAndQueryString)
  const queryStringFromUrl = R.join('?', R.tail(splitUrlAndQueryString))

  const concatArrays = (l, r) => (_.isArray(l) ? l.concat(r) : r)

  const queryString = _.thru(
    params,
    _.snakifyKeys,
    R.mergeWith(concatArrays, queryStringToObject(queryStringFromUrl)),
    objectToQueryString,
    R.unless(_.isBlank, R.concat('?'))
  )

  return R.join('', [url, queryString])
}

export const normalizeRequestOptions = ({
  headers: headersIn,
  params: paramsIn,
  url: urlIn,
  useReplica,
  withTotalEntries,
  ...options
} = {}) => {
  _.throwWhen(R.has('order', paramsIn), 'The order param is deprecated, use sort instead')

  const params = _.thru(
    paramsIn,
    L.modify('q', formatFilterParams),
    L.modify('sort', stringifySort)
  )

  const url = joinUrlWithParams(asAbsoluteUrl(urlIn), params)

  const headers = R.mergeAll([
    { 'X-No-Default-Filters': 'true' },
    useReplica && { 'X-Replica': 'true' },
    withTotalEntries && { 'X-Total-Entries': 'true' },
    headersIn,
  ])

  return { ...options, headers, url }
}

const stringifySort = R.ifElse(
  R.isNil,
  R.always(undefined),
  R.pipe(
    R.when(_.isString, _.castArray),
    R.when(_.isArray, R.pipe(_.zipAll('asc'), R.fromPairs)),
    value => _.snakifyKeys(value, { ignore: ['|', '.'] }),
    R.toPairs,
    R.map(R.join(':')),
    R.join(',')
  )
)

export const unwrapCollection = data => {
  const collectionKey = findCollectionKey(data)
  return collectionKey ? data[collectionKey] : data
}
