import * as _ from 'modules/util'
import * as R from 'ramda'
import { use, useMemo } from 'react'
import { FlashMessagesContext } from './context'
import { typeIncludedIn } from './util'

const formatMessage = ({
  action,
  dismissible = true,
  htmlSafe,
  icon,
  id = _.randomId(),
  message,
  sanitize = htmlSafe,
  timeout = 4000,
  type,
}) => {
  if (!message) return

  return {
    action,
    config: _.when(type === 'error', { bounce: 0.6, tension: 125, friction: 14 }),
    dismissible,
    id,
    icon,
    message,
    sanitize,
    timeout,
    type,
  }
}

export const makeAddMessage = setMessages => value => {
  if (_.isFunction(value)) {
    setMessages(value)
    return
  }

  const formattedMessage = formatMessage(value)
  if (!formattedMessage) return

  if (formattedMessage.type === 'error') {
    setMessages(R.prepend(formattedMessage))
  } else {
    setMessages(R.append(formattedMessage))
  }
}

export const useMessages = (types = ['error', 'info', 'success', 'warning'], ...args) => {
  const messages = use(FlashMessagesContext).messages || []
  return messages.filter(typeIncludedIn([].concat(types).concat(args)))
}

export const useSetMessages = () => {
  const { setMessages } = use(FlashMessagesContext)
  return useMemo(() => makeAddMessage(setMessages), [setMessages])
}
