import lodashCamelCase from 'lodash/camelCase'
import lodashKebabCase from 'lodash/kebabCase'
import lodashSnakeCase from 'lodash/snakeCase'
import lodashTruncate from 'lodash/truncate'
import * as R from 'ramda'
import { isNumber, isString } from './conditions'
import { castArray } from './defaults'
import { thru } from './functions'

export const article = value => (value.match(/^[aeiou]/) ? 'an' : 'a')

export const replaceApostrophes = R.when(R.is(String), R.replace(/'/g, '’'))

export const camelCase = (value, options) => applyFunctionToString(lodashCamelCase, value, options)

export const kebabCase = lodashKebabCase

export const capitalizeFirstLetter = str => {
  if (!isString(str)) return
  if (str === '') return ''
  return str[0].toUpperCase() + str.slice(1)
}

export const uncapitalizeFirstLetter = str => {
  if (!isString(str)) return
  return str[0].toLowerCase() + str.slice(1)
}

export const joinStrings = (array, ...values) => {
  const result = castArray(array).concat(values).filter(isString).join(' ')
  return result === '' ? undefined : result
}

export const humanize = string => thru(string, snakeCase, R.replace(/_/g, ' '))

export const listToString = list =>
  list.length === 1
    ? list[0]
    : list
        .slice(0, -1)
        .join(', ')
        .concat(` and ${list.slice(-1)}`)

export const pluralize = R.curry((string, count) =>
  count === 1 ? string : thru(string, R.when(isString, R.concat(R.__, 's')))
)

export const randomId = ({ prefix = 'id' } = {}) =>
  `${prefix}-` + Math.floor(Math.random() * 100000000)

export const singularize = string => {
  if (!isString(string)) {
    return string
  } else if (string.endsWith('ses')) {
    return string.slice(0, -2)
  } else if (string.endsWith('s')) {
    return string.slice(0, -1)
  } else {
    return string
  }
}

export const snakeCase = (value, options) => applyFunctionToString(lodashSnakeCase, value, options)

export const toNumber = value => {
  if ([undefined, false, true, null, ''].includes(value)) return undefined
  const number = Number(value)
  return isNumber(number) ? number : undefined
}

export const toString = R.pipe(R.unless(R.is(String), R.toString))

export const trim = R.when(R.is(String), R.pipe(R.trim, R.when(R.isEmpty, R.always(undefined))))

export const truncate = lodashTruncate

const applyFunctionToString = (fn, value, { ignore } = {}) => {
  const ignoredChars = castArray(ignore)
  if (!isString(value) || !ignoredChars.length) return fn(value)

  const processedValue = thru(
    value,
    ...ignoredChars.map((char, index) => str => str.replaceAll(char, `##${index}##`))
  )

  return processedValue
    .split(/(##\d+##)/)
    .map(part => {
      const placeholderMatch = part.match(/##(\d+)##/)

      if (placeholderMatch) {
        return ignoredChars[parseInt(placeholderMatch[1], 10)]
      } else {
        return fn(part)
      }
    })
    .join('')
}
